import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'
import Breadcrumbs from '~components/layouts/Breadcrumbs'

const PartnershipPage = () => {
  return (
    <Layout>
      <SEO
        title="Partnerships"
        pathname="/partnerships"
        description="Leather Spa, pioneer in the art of leather care, has partnered with Saks Fifth Avenue to create a unique leather accessory repair experience at their flagship store"
      />
      <Main>
        <Container>
          <Breadcrumbs title="Partnerships" />
          <Row className="mb-5">
            <Col xs={12} lg={6}>
              <img
                src={'https://leatherspa.imgix.net/Partnership_Image_LS-Saks.jpg'}
                className="w-100"
                alt="leather spa and saks store front"
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="text-center d-flex justify-content-center align-items-center"
              style={{ color: scssVars.darkBrown }}
            >
              <section className="px-lg-5 pt-3 pt-lg-0">
                <img
                  src={'https://leatherspa.imgix.net/Partnership_Logos-LSxSAKS@2x.jpg'}
                  className="my-3"
                  alt="leather spa and saks fifth logo"
                  style={{ width: 303, height: 105 }}
                />
                <p>
                  Leather Spa, pioneer in the art of leather care, has partnered with Saks Fifth Avenue to create a unique leather accessory repair experience at their flagship store.
                </p>
              </section>
            </Col>
          </Row>
          <Row className="my-5 pb-lg-5">
            <Col xs={12} lg={6}>
              <img
                src={'https://leatherspa.imgix.net/Partnership_UPS.png'}
                className="w-100"
                alt="leather spa and saks store front"
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="text-center d-flex justify-content-center align-items-center"
              style={{ color: scssVars.darkBrown }}
            >
              <section className="px-lg-5 pt-3 pt-lg-0">
                <img
                  src={'https://leatherspa.imgix.net/Partnership_UPS_LS_Logo.png'}
                  className="my-3"
                  alt="leather spa and saks fifth logo"
                  style={{ width: 303, height: 105 }}
                />
                <p>
                We have partnered with The UPS Store® to provide you a convenient way to drop off and send repairs to us. With over 4,900 The UPS Store® locations, repairing items has never been more convenient. It's basically like having 4,900 LEATHER SPA drop-off locations.
                </p>
              </section>
            </Col>
          </Row>
        </Container>
      </Main>
    </Layout>
  )
};

export default PartnershipPage
